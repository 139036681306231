// Variables for reuse
$primary-color: #007bff;
$border-color: #ccc;
$shadow-color: rgba(0, 0, 0, 0.1);
$text-color: #fff;

// Mixin for box-shadow
@mixin box-shadow($color) {
  box-shadow: 0 0 10px $color;
}

// Chat container styling
.chat-container {
  z-index: 9999;
  position: fixed;
  bottom: 75px;
  right: 20px;
  width: 350px;
  height: 600px;
  border: 1px solid $border-color;
  border-radius: 10px;
  @include box-shadow($shadow-color);
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;

  &.hidden {
    transform: translateY(120%);
  }
  
  // Chat header styling
  .chat-header {
    background-color: $primary-color;
    color: $text-color;
    padding: 10px;
    text-align: center;
    cursor: pointer;
  }
  
  // Chat iframe styling
  .chat-iframe {
    flex: 1;
    width: 100%;
    border: none;
  }
}

// Toggle button styling
.chat-toggle-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: $primary-color;
  color: $text-color;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  @include box-shadow($shadow-color);
  z-index: 1000;
}
